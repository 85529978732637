import React from 'react';
import classNames from 'classnames';

export interface LoaderProps {
  isFullScreen?: boolean;
}

export const Loader = ({isFullScreen}: LoaderProps) => {
  return (
    <div className={classNames('flex justify-center items-center', {
      'fullscreen': isFullScreen
    })}>
      <div className="loader">
        <div className="loader__bounce"/>
        <div className="loader__bounce loader__bounce--early"/>
      </div>
    </div>
  )
};

Loader.defaultProps = {
  isFullScreen: true
}
