import { useEffect, useState } from 'react';
import { getResource } from '../helpers/api';
import { ApiCollectionName } from '../helpers/enums';
import { Client, ClientUsage, ClientUsageResponse } from '../helpers/interfaces';

export const useClientUsage = (client?: Client): ClientUsage[] => {
  const [usage, setUsage] = useState<ClientUsage[]>([]);

  useEffect(() => {
    if (!client) {
      return client;
    }

    (async () => {
      let res: ClientUsageResponse = await getResource(`/${ApiCollectionName.Client}/${client.id}/usage`);

      const sortedUsage = Object.keys(res ?? {}).reduce((total: ClientUsage[], current: string) => {
        return [
          ...total,
          {
            startDate: current,
            usage: res[current]
          }
        ]
      }, []).sort((a, b) => {
        return a.startDate > b.startDate ? 1 : 0
      });


      setUsage([{ startDate: '2021-06-05 09:37', usage: [20, 30, 50]},...sortedUsage]);
    })();
  }, [client])

  return usage;
}
