import { API } from 'aws-amplify';
import { EntityOverviewResponse } from '@jumpventures/stack/helpers/interfaces';
import { ApiCollectionName } from './enums';
import { KeyValue } from './interfaces';

const API_NAME = 'postcodeapi-console-api'

export const getEntities = async (apiCollectionName: ApiCollectionName, orgId: string = ''): Promise<EntityOverviewResponse> => {
  if (!orgId) {
    return API.get(API_NAME, `/${apiCollectionName}`, null);
  }
  return API.get(API_NAME, `/${orgId}/${apiCollectionName}`, null);
};

export const createEntity = (apiCollectionName: ApiCollectionName|string, entity: any, orgId: string = '') => {
  if (!orgId) {
    return API.post(API_NAME, `/${apiCollectionName}`, {
      body: entity
    });
  }
  return API.post(API_NAME, `/${orgId}/${apiCollectionName}`, {
    body: entity
  });
};

export const getEntity = async (apiCollectionName: ApiCollectionName, queryParams: KeyValue = {}) => {
  const queryStringParameters = Object.keys(queryParams).reduce((total, current) => {
    if (!queryParams[current]) {
      return total;
    }

    return {
      ...total,
      [current]: queryParams[current]
    }
  }, {});
  return API.get(API_NAME, `/${apiCollectionName}`, { queryStringParameters });
};

export const getEntityById = async (apiCollectionName: ApiCollectionName, id: string, orgId: string = '') => {
  if (!orgId) {
    return API.get(API_NAME, `/${apiCollectionName}/${id}`, null);
  }
  return API.get(API_NAME, `/${orgId}/${apiCollectionName}/${id}`, null);
};

export const getResource = async (path: string, orgId: string = '') => {
  return API.get(API_NAME, `${path}`, null);
};

export const createResource = async (path: string, entity: any) => {
  return API.post(API_NAME, path, {
    body: entity
  });
}

export const getResources = async (path: string, orgId: string = '') => {
  const res = await getResource(path, orgId);

  return res._embedded.items;
}

export const deleteResource = async (path: string, orgId: string = '') => {
  return API.del(API_NAME, `${path}`, null);
};

export const updateResource = async (path: string, body: any = {}, orgId: string = '') => {
  return API.post(API_NAME, `${path}`, {
    body
  });
};

export const updateEntity = (apiCollectionName: ApiCollectionName, id: string, body: any, orgId: string = '') => {
  if (!orgId) {
    return API.put(API_NAME, `/${apiCollectionName}/${id}`, {
      body
    });
  }
  return API.put(API_NAME, `/${orgId}/${apiCollectionName}/${id}`, {
    body
  });
};

export const deleteEntity = (apiCollectionName: ApiCollectionName, id: string, orgId: string = '') => {
  if (!orgId) {
    return API.del(API_NAME, `/${apiCollectionName}/${id}`, {});
  }
  return API.del(API_NAME, `/${orgId}/${apiCollectionName}/${id}`, {});
};
