import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonTheme, ButtonType } from '@jumpventures/stack/helpers/enums';
import { Button } from '@jumpventures/stack/components/button';
import { Loader } from '../Loader/Loader';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import CancelSVG from './cancel.svg';
import DisableSVG from './disable.svg';
import EnableSVG from './enable.svg';
import UpgradeSVG from './upgrade.svg';

const iconMapping = {
  cancel: CancelSVG,
  disable: DisableSVG,
  upgrade: UpgradeSVG,
  enable: EnableSVG,
}

interface ApiButtonProps {
  text: string;
  endpoint: () => Promise<any>;
  disabled?: boolean;
  className?: string;
  outline?: boolean;
  icon: 'cancel'|'disable'|'upgrade'|'enable';
  theme?: ButtonTheme;
  onSuccess: (data: any) => void;
  onFailure: (err: string) => void;
}

export const ApiButton = ({theme, text, outline, icon, endpoint, disabled, className, onFailure, onSuccess}: ApiButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useRef<boolean>(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    }
  }, []);

  const onClick = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await endpoint();

      onSuccess(response);
    } catch (e) {
      if (mountedRef.current) {
        onFailure(e);
      }
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [onFailure, onSuccess, endpoint])

  return (
    <Button
      type={ButtonType.Button}
      outline={outline}
      onClick={onClick}
      theme={theme || ButtonTheme.secondary}
      className={classNames('client-action btn--with-icon', className)}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <div className="btn__icon">
          <Loader isFullScreen={false}/>
        </div>
      ) : (
        <ReactSVG className="btn__icon" src={iconMapping[icon]}/>
      )}
      {text}
    </Button>
  )
}

ApiButton.defaultProps = {
  outline: true
}
