import React from "react";
import {Redirect, Route} from "react-router-dom";
import {Pages} from "../helpers/pages";

export default function AppliedRoute({ component: C, appProps, ...rest }: any) {
  if (!appProps.isAuthenticated && ![Pages.Login].includes(rest.path)) {
    return <Redirect to={{
      pathname: Pages.Login,
      state: { from: rest.location}
    }}/>;
  } else if (appProps.isAuthenticated && [Pages.Login].includes(rest.path)) {
    return <Redirect to={Pages.Clients} />;
  }

  return (
    <Route {...rest} render={props => <C {...props} {...appProps} />} />
  );
}
