import React, { useState } from 'react';
import classnames from 'classnames';
import LoopSvg from './loop.svg';
import { ReactSVG } from 'react-svg';

interface SearchFilterProps {
  onSubmit: (filter?: string) => void;
}

const SearchFilter = ({onSubmit}: SearchFilterProps) => {
  const [filter, setFilter] = useState<string>('');
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      onSubmit(filter);
    }} className={classnames('search-filter', {
      'has-focus': hasFocus
    })}>
      <input
        value={filter}
        onFocus={setHasFocus.bind(null, true)}
        onBlur={setHasFocus.bind(null, false)}
        onChange={(e) => setFilter(e.target.value)}
        type="text"
      />
      <button>
        <ReactSVG src={LoopSvg} />
      </button>
    </form>
  );
}

export default SearchFilter;
