import React from "react";
import { hydrate, render } from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import {Amplify, API} from 'aws-amplify';
import App from './app';
import { ToastProvider } from 'react-toast-notifications';
import { ApiService } from '@jumpventures/stack/helpers/api';


const RoutedApp = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
    },
    API: {
      endpoints: [
        {
          name: "postcodeapi-console-api",
          endpoint: process.env.REACT_APP_API_GATEWAY_URL,
          region: process.env.REACT_APP_AWS_REGION
        },
      ]
    }
  });

  ApiService.initialize({
    ...Amplify,
    API: API
  });

  return (
    <Router>
      <ToastProvider autoDismiss={true}>
        <App/>
      </ToastProvider>
    </Router>
  );
};

const rootElement = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(<RoutedApp/>, rootElement);
} else {
  render(<RoutedApp/>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
