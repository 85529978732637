import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {Client, ClientFilter, ClientUpdateBody, UsageMonth, UsagePeriod} from '../../helpers/interfaces';
import { getEntity, updateEntity, updateResource } from '../../helpers/api';
import { ApiCollectionName } from '../../helpers/enums';
import {
  updateClientConstraints,
  companyContact,
  companyInvoice,
  companyMainData,
  contract,
  keyLabelMapping,
  readonlyFields,
  widthMapping, formGrouping
} from './config';
import { useToasts } from 'react-toast-notifications';
import { useClientUsage } from '../../hooks/useClientUsage';
import { Form } from '@jumpventures/stack/components/form';
import Section  from '@jumpventures/stack/components/section';
import { Page }  from '@jumpventures/stack/components/page';
import { ClientSidebar } from '../../components/client-sidebar/client-sidebar';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import { FormFields } from '@jumpventures/stack/helpers/interfaces';
import { formatDate } from '@jumpventures/stack/helpers/format-date';
import moment from 'moment';
import {PageSize} from "@jumpventures/stack/helpers/enums";

const dateOptions: any = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}

const dateBetweenRange = (start: Date, end: Date, current:  Date) => {
  start.setHours(0,0,0,0);
  end.setHours(0,0,0,0);
  current.setHours(0,0,0,0);
  return start.getTime() <= current.getTime()
    && current.getTime() <= end.getTime();
}

const dateInMapping = (date: Date, monthMapping: UsageMonth[]) => {
  return monthMapping.findIndex((month) => {
    const formattedStartDate = formatDate(month.startDate);
    const formattedEndDate = formatDate(month.endDate);
    return dateBetweenRange(formattedStartDate, formattedEndDate, date);
  });
}

const addMonthToMapping = (monthMapping: UsageMonth[]) => {
  const lastsMonth = monthMapping[monthMapping.length - 1];
  const startDate = moment(formatDate(lastsMonth.startDate)).subtract(1, 'month').toDate();
  const endDate = formatDate(lastsMonth.startDate);
  endDate.setDate(endDate.getDate() - 1);

  return monthMapping.push({
    days: [],
    endDate: endDate.toString(),
    startDate: startDate.toString()
  })
}

const addDayToMapping = (item: {
  date: string;
  count: number;
}, monthMapping: UsageMonth[], stop = false) => {
  const index = dateInMapping(formatDate(item.date), monthMapping)
  if (index === -1 && !stop) {
    addMonthToMapping(monthMapping);
    addDayToMapping(item, monthMapping);
    return;
  }

  if (index && monthMapping[index]) {
    monthMapping[index].days.push(item);
  }
}

const groupForm = (client: Client, groupArray: (string|string[])[]) => {
  return groupArray.reduce((total: FormFields, current) => {
    if (Array.isArray(current)) {
      return [...total, groupForm(client, current)];
    }

    const fieldKey = Object.keys(client).find(key => current === key);

    if (!fieldKey) {
      return total;
    }

    return [
      ...total,
      {
        key: fieldKey,
        value: client[fieldKey] !== null ? client[fieldKey] : undefined,
        readonly: readonlyFields.includes(fieldKey),
        constraints: updateClientConstraints[fieldKey],
        width: widthMapping[fieldKey] ?? '100',
        label: keyLabelMapping[fieldKey] || fieldKey
      }
    ]
  }, []);
}

export const Clients = () => {
  const [filter, setFilter] = useState<ClientFilter>({q: ''});
  const [client, setClient] = useState<Client>();
  const usage = useClientUsage(client);
  const {addToast} = useToasts();
  const [periodUsage, setPeriodUsage] = useState<UsagePeriod>({});
  const [monthUsages, setMonthUsages] = useState<UsageMonth[]>([]);
  const [notFound, setNotFound] = useState(false);
  const [openRow, setOpenRow] = useState<number|null>(null);

  useEffect(() => {
    if (!client || !usage) {
      return;
    }
    const subscriptionStartDate = formatDate(client.subscriptionStartsAt);
    const subscriptionStartDay = (subscriptionStartDate.getDate() - 1) > 0 ? subscriptionStartDate.getDate() - 1 : subscriptionStartDate.getDate();

    usage.forEach((clientUsage) => {
      const clientUsageDate = formatDate(clientUsage.startDate);
      const usagePeriods: UsagePeriod = {};
      let isCounting = false;
      let currentDateKey: string;

      clientUsage.usage.forEach((usageItem, index) => {
        if (isCounting && clientUsageDate.getDate() === subscriptionStartDay) {
          usagePeriods[currentDateKey].finished = true;
          isCounting = false;
        }

        if (clientUsageDate.getDate() === subscriptionStartDay && !isCounting) {
          currentDateKey = clientUsageDate.toLocaleString('nl', dateOptions);
          isCounting = true;
          usagePeriods[currentDateKey] = {
            finished: false,
            startDate: clientUsageDate.toString(),
            endDate: clientUsageDate.toString(),
            count: usageItem
          };
        } else if (isCounting) {
          usagePeriods[currentDateKey] = {
            ...usagePeriods[currentDateKey],
            count: usagePeriods[currentDateKey].count + usageItem,
            endDate: clientUsageDate.toString()
          }
        }

        if (index === 0) {
          currentDateKey = clientUsageDate.toLocaleString('nl', dateOptions);
          isCounting = true;
          usagePeriods[currentDateKey] = {
            finished: false,
            untilNow: true,
            startDate: clientUsageDate.toString(),
            endDate: clientUsageDate.toString(),
            count: usageItem
          }
        }

        clientUsageDate.setDate(clientUsageDate.getDate() - 1);
      });

      setPeriodUsage(usagePeriods);
    });
  }, [usage, client]);

  useEffect(() => {
    if (!client || !usage) {
      return;
    }

    let allDays: {date: string; count: number}[] = [];
    let firstDate = formatDate(client.subscriptionStartsAt);
    firstDate.setMonth(firstDate.getMonth() - 3);

    usage.forEach(use => {
      const formattedDate = formatDate(use.startDate);

      use.usage.forEach((count) => {
        const currentDate = moment(formattedDate).format('YYYY-MM-DD');
        const index = allDays.findIndex(day => day.date === currentDate);

        if (index !== -1) {
          allDays[index].count += count;
        } else {
          allDays.push({
            date: currentDate,
            count
          });
        }

        formattedDate.setDate(formattedDate.getDate() - 1);
      })
    });

    let currentLoopedDate = new Date();
    let monthMapping: UsageMonth[] = [];
    let endDateCurrentMonth = new Date();

    do {
      const currentDateToString = moment(currentLoopedDate).format('YYYY-MM-DD');
      if (!monthMapping.length) {
        const startDay = formatDate(client.subscriptionStartsAt).getMonth() - 3;
        const startDate = formatDate(currentLoopedDate.toString());
        startDate.setMonth(startDate.getMonth() - 3);
        startDate.setDate(startDay);
        endDateCurrentMonth = formatDate(startDate.toString());
        monthMapping.push({
          days: [],
          endDate: currentLoopedDate.toString(),
          startDate: startDate.toString()
        })
      }

      const item = allDays.find(item => item.date === currentDateToString);

      if (item) {
        const existsIndex = dateInMapping(formatDate(currentDateToString), monthMapping)

        if (existsIndex !== -1) {
          monthMapping[existsIndex].days.push(item)
        } else {
          addDayToMapping(item, monthMapping);
        }
      }

      if (endDateCurrentMonth.getMonth() > currentLoopedDate.getMonth()) {
        endDateCurrentMonth.setMonth(currentLoopedDate.getMonth());
      }

      currentLoopedDate.setDate(currentLoopedDate.getDate() - 1);
    } while (currentLoopedDate.getTime() > firstDate.getTime())

    setMonthUsages(monthMapping);
  }, [usage, client]);


  const clientFormFields = useMemo(() => {
    if (!client) {
      return [];
    }

    const companyMainFormFields = groupForm(client, companyMainData);
    const companyContactFormFields = groupForm(client, companyContact);
    const companyInvoiceFormFields = groupForm(client, companyInvoice);
    const contractFormFields = groupForm(client, contract);

    return [
      ...companyMainFormFields,
      ...companyContactFormFields,
      ...companyInvoiceFormFields,
      ...contractFormFields,
    ]
  }, [client]);

  const fetchUser = useCallback(async () => {
    if (!filter.q) {
      return;
    }
    try {
      const res = await getEntity(ApiCollectionName.Client, filter);
      setClient(res);
    } catch (e) {
      console.error(e);
      setNotFound(true);
    }
  }, [filter]);

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, [filter, fetchUser]);

  const onChangeFilter = useCallback(async (query?: string) => {
    setClient(undefined);
    setNotFound(false);
    setFilter({
      q: query
    });
    setMonthUsages([]);
    setPeriodUsage({});
  }, []);

  const saveClient = useCallback(async (data: Client) => {
    const body: ClientUpdateBody = {
      companyName: data.companyName,
      contactEmail: data.contactEmail,
      contactFirstName: data.contactFirstName,
      contactLastName: data.contactLastName,
      invoiceCity: data.invoiceCity,
      invoiceEmail: data.invoiceEmail,
      invoicePostcode: data.invoicePostcode,
      invoiceNumber: data.invoiceNumber,
      invoiceReference: data.invoiceReference,
      invoiceStreet: data.invoiceStreet,
      invoiceToAttention: data.invoiceToAttention
    }

    return new Promise<void>(async (resolve, reject) => {
      try {
        await updateEntity(ApiCollectionName.Client, data.id, body);
        await fetchUser();
        addToast(`${data.companyName} is succesvol bijgewerkt!`, {appearance: 'success'});
        resolve();
      } catch (e) {
        addToast(
          <>
            Er ging iets mis met het bijwerken van {data.companyName}! <br/><strong>Error: {e.response?.data?.detail}</strong>
          </>, {appearance: 'error'});
        if (e.response.data) {
          reject(e.response.data);
        }
      }
    })
  }, [addToast, fetchUser]);

  const cancelSubscription = useCallback(async () => {
    if (!client) {
      return;
    }

    await updateResource(`/${ApiCollectionName.Client}/${client.id}/cancel-subscription`);
    await fetchUser();
  }, [client, fetchUser]);

  const scheduleCancelTask = useCallback(async () => {
    if (!client) {
      return;
    }

    await updateResource(`/${ApiCollectionName.Client}/${client.id}/schedule-cancel-task`);
    await fetchUser();
  }, [client, fetchUser]);

  const disableKey = useCallback(async () => {
    if (!client) {
      return;
    }

    await updateResource(`/${ApiCollectionName.Client}/${client.id}/disable-key`);
    await fetchUser();
  }, [client, fetchUser]);

  const enableKey = useCallback(async () => {
    if (!client) {
      return;
    }

    await updateResource(`/${ApiCollectionName.Client}/${client.id}/enable-key`);
    await fetchUser();
  }, [client, fetchUser]);

  const recollectInvoices = useCallback(async () => {
    if (!client) {
      return;
    }

    await updateResource(`/${ApiCollectionName.Client}/${client.id}/recollect-invoices`);
    await fetchUser();
  }, [client, fetchUser]);

  const upgrade = useCallback(async () => {
    if (!client) {
      return;
    }

    await updateResource(`/${ApiCollectionName.Client}/${client.id}/upgrade`);
    await fetchUser();
  }, [client, fetchUser]);

  const onAPIButtonFailure = useCallback((err) => {
    console.error(err);
    addToast(`Er ging iets mis!`, {appearance: 'error'});
  }, [addToast]);

  return (
    <Page pageSize={PageSize.Large} sideBar={
      <ClientSidebar
        client={client}
        scheduleCancelTask={scheduleCancelTask}
        cancelSubscription={cancelSubscription}
        disableKey={disableKey}
        enableKey={enableKey}
        recollectInvoices={recollectInvoices}
        upgrade={upgrade}
        onAPIButtonFailure={onAPIButtonFailure}
      />
    }>
      <div className="page-header">
        <div className="page-header__actions">
          <div><h2 className="page__title heading">Clients</h2></div>
          <div className="btn-group">
            <SearchFilter
              onSubmit={onChangeFilter}
            />
          </div>
        </div>
      </div>
      <Section isSideless isTransparent large classNames="overview__table-container">
        { client && (
          <>
            <Form
              onSubmit={saveClient}
              buttonSubmitText="Save"
              showBorders={false}
              constraints={updateClientConstraints}
              formGrouping={formGrouping}
              fields={clientFormFields}
            />
          </>
        )}
        { notFound && (
          <h1>Client niet gevonden</h1>
        )}
      </Section>

      { !!Object.keys(periodUsage).length && (
        <Section isSideless isTransparent>
          <h2>Past periods</h2>

          <table className="table overview__table">
            <thead>
            <tr>
              <th>Period</th>
              <th>Usage</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(periodUsage).map((key, index) => {
              const periodItem = periodUsage[key];
              if (!periodItem.finished && !periodItem.untilNow) {
                return null;
              }

              return (
                <tr key={index} className="tr-disable-hover">
                  {periodItem.untilNow ? (
                    <td>{ formatDate(periodItem.endDate).toLocaleString('nl', dateOptions)} t/m {new Date().toLocaleString('nl', dateOptions)} (vandaag)</td>
                  ) : (
                    <td>{formatDate(periodItem.endDate).toLocaleString('nl', dateOptions)} t/m {formatDate(periodItem.startDate).toLocaleString('nl', dateOptions)}</td>
                  )}
                  <td>{periodItem.count}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </Section>
      ) }

      { !!monthUsages?.length && (
        <Section isSideless isTransparent>
          <h2>Usage</h2>

          <table className="table overview__table">
            <thead>
            <tr>
              <th>Date</th>
              <th>Usage</th>
            </tr>
            </thead>
            <tbody>
            {monthUsages.map((monthUsage, index) => {
              const total = monthUsage.days.reduce((total, current) => total+current.count, 0);

              return (
                <React.Fragment key={index}>
                  <tr className="tr-clickable" onClick={setOpenRow.bind(null, openRow !== index ? index : null)}>
                    <td>
                      <strong>{formatDate(monthUsage.startDate).toLocaleString('nl', dateOptions)} t/m {formatDate(monthUsage.endDate).toLocaleString('nl', dateOptions)}</strong>
                    </td>
                    <td><strong>{total}</strong></td>
                  </tr>
                  {openRow === index  && (
                    monthUsage.days.map((usageItem, usageItemIndex) => {
                      return (
                        <tr key={usageItemIndex}>
                          <td>{formatDate(usageItem.date).toLocaleString('nl', dateOptions)}</td>
                          <td>{usageItem.count}</td>
                        </tr>
                      );
                    })
                  )}
                </React.Fragment>
              )
            })}
            </tbody>
          </table>
        </Section>
      )}
    </Page>
  )
}
