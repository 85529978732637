import { KeyValue } from '../../helpers/interfaces';

export const keyLabelMapping: KeyValue = {
  q: 'Factuurnummer/ID/API Key',
  id: 'ID',
  apiKey: 'API Key',
  companyName: 'Bedrijfsnaam',
  contactFirstName: 'Voornaam',
  contactLastName: 'Achternaam',
  contactEmail: 'E-mail',
  invoiceEmail: 'Factuur e-mail',
  motivation: 'Motivatie',
  invoiceCity: 'Woonplaats',
  invoiceStreet: 'Straat',
  invoiceNumber: 'Nummer',
  invoicePostcode: 'Postcode',
  invoiceReference: 'Referentie',
  invoiceToAttention: 'Ter attentie van',
  subscriptionStartsAt: 'Startdate',
  subscriptionEndsAt: 'Enddate',
  subscriptionPlan: 'Plan',
  subscriptionPeriod: 'Periode',
  paymentMethod: 'Betaalwijze',
  createdAt: 'Registration date'
}

export const filterValidation = {
  q: {
    oneOfRequired: ['q']
  }
}

export const filterFormFields = [{
  key: 'q',
  value: '',
  label: keyLabelMapping.q
}]

export const companyMainData = ['companyName', 'id', 'apiKey'];
export const companyContact = ['contactFirstName', 'contactLastName', 'contactEmail', 'motivation'];
export const companyInvoice = ['invoiceToAttention', 'invoiceStreet', 'invoiceNumber', 'invoicePostcode', 'invoiceCity', 'invoiceEmail'];
export const contract = ['subscriptionStartsAt', 'subscriptionEndsAt','subscriptionPlan', 'subscriptionPeriod', 'paymentMethod', 'createdAt'];

export const formGrouping: any = [
  companyMainData,
  [['contactFirstName', 'contactLastName'], 'contactEmail', 'motivation'],
  ['invoiceToAttention', ['invoiceStreet', 'invoiceNumber'], ['invoicePostcode', 'invoiceCity'], 'invoiceEmail'],
  [['subscriptionStartsAt', 'subscriptionEndsAt'],['subscriptionPlan', 'subscriptionPeriod'], ['paymentMethod', 'createdAt']]
]

export const widthMapping = {
  invoiceStreet: 75,
  invoiceNumber: 25,
  invoicePostcode: 25,
  invoiceCity: 75
}

export const readonlyFields = [
  'id',
  'apiKey',
  'createdAt',
  'motivation',
  'paymentMethod',
  'subscriptionEndsAt',
  'subscriptionPeriod',
  'subscriptionPlan',
  'subscriptionStartsAt'
];

const NOT_BLANK = {
  presence: {
    allowEmpty: false
  }
}

export const updateClientConstraints = {
  companyName: {
    ...NOT_BLANK
  },
  contactFirstName: {
    ...NOT_BLANK
  },
  contactLastName: {
    ...NOT_BLANK
  },
  contactEmail: {
    ...NOT_BLANK
  },
  invoiceEmail: {
    ...NOT_BLANK
  },
  invoiceCity: {
    ...NOT_BLANK
  },
  invoiceNumber: {
    ...NOT_BLANK
  },
  invoiceStreet: {
    ...NOT_BLANK
  },
  invoicePostcode: {
    ...NOT_BLANK
  },
}
