import React from 'react';
import { ApiButton } from '../ApiButton/ApiButton';
import { useToasts } from 'react-toast-notifications';
import { Client } from '../../helpers/interfaces';
import { ReactSVG } from 'react-svg';
import ActionsSVG from './actions.svg';
import Button from '@jumpventures/stack/components/button';

interface ClientSidebarProps {
  scheduleCancelTask: () => Promise<any>;
  cancelSubscription: () => Promise<any>;
  disableKey: () => Promise<any>;
  enableKey: () => Promise<any>;
  upgrade: () => Promise<any>;
  recollectInvoices: () => Promise<any>;
  onAPIButtonFailure: (err) => void;
  client?: Client;
}

export const ClientSidebar = ({ scheduleCancelTask, recollectInvoices, cancelSubscription, enableKey, upgrade, disableKey, onAPIButtonFailure, client }: ClientSidebarProps) => {
  const { addToast } = useToasts();

  if (!client) {
    return null;
  }

  return (
    <div>
      <h3 className="sidebar-header">
        <ReactSVG src={ActionsSVG} />
        Actions
      </h3>
      <div className="mt-2 client-actions">
        <ApiButton
          text="Beëindig na contractperiode"
          endpoint={scheduleCancelTask}
          icon="cancel"
          className="client-action--red"
          onSuccess={() => {
            addToast(`${client.companyName}'s schedule is succesvol beëindigd!`, { appearance: 'success' });
          }}
          onFailure={onAPIButtonFailure}
        />
        <ApiButton
          text="Beëindig per direct"
          endpoint={cancelSubscription}
          className="client-action--red"
          icon="cancel"
          onSuccess={() => {
            addToast(`${client.companyName}'s abonement is succesvol beëindigd!`, { appearance: 'success' });
          }}
          onFailure={onAPIButtonFailure}
        />
        <ApiButton
          text="Disable API Key"
          endpoint={disableKey}
          className="client-action--red"
          icon="disable"
          onSuccess={() => {
            addToast('API Key disabled', { appearance: 'success' });
          }}
          onFailure={onAPIButtonFailure}
        />
        {client.paymentMethod === 'creditcard' &&
          <ApiButton
            text="Recollect invoices"
            endpoint={recollectInvoices}
            className="client-action--green"
            icon="enable"
            onSuccess={() => {
              addToast(`${client.companyName}'s facutren opnieuw opgehaald!`, { appearance: 'success' });
            }}
            onFailure={onAPIButtonFailure}
          />
        }
        <ApiButton
          text="Enable API Key"
          endpoint={enableKey}
          className="client-action--green"
          icon="enable"
          onSuccess={() => {
            addToast('API Key enabled', { appearance: 'success' });
          }}
          onFailure={onAPIButtonFailure}
        />
        {(['rookie', 'small', 'standard', 'plus'].includes(client.subscriptionPlan) &&
          <ApiButton
            text="Upgrade"
            endpoint={upgrade}
            icon="upgrade"
            className="client-action--green"
            onSuccess={() => {
              addToast('Client upgraded', { appearance: 'success' });
            }}
            onFailure={onAPIButtonFailure}
          />
        )}
        {client.paymentMethod === 'creditcard' &&
          <Button onClick={() => navigator.clipboard.writeText(`https://www.postcodeapi.nu/payment/?c=${client.id}`)}>Copy creditcard link</Button>
        }
        {client.paymentMethod === 'directDebit' &&
          <Button onClick={() => navigator.clipboard.writeText(`https://www.postcodeapi.nu/payment/?d=${client.id}`)}>Copy incasso link</Button>
        }
      </div>
    </div>
  )
}
