export class Storage {
  private static getKey(key: string) {
    return `apiwatch_${key}`;
  }

  static get(key: string) {
    const value = localStorage.getItem(Storage.getKey(key));
    if (!value) {
      return undefined;
    }
    return JSON.parse(value);
  }

  static set(key: string, value: any) {
    const stringifiedValue = JSON.stringify(value);

    localStorage.setItem(Storage.getKey(key), stringifiedValue);
  }

  static remove(key: string) {
    localStorage.removeItem(Storage.getKey(key));
  }
}
