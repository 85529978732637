import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.scss';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import Login from './pages/Login';
import { Clients } from './pages/Clients';
import AppliedRoute from "./components/AppliedRoute";
import { Auth } from 'aws-amplify';
import { Pages } from './helpers/pages';
import Application from '@jumpventures/stack/components/app';
import { Navigation } from '@jumpventures/stack/components/navigation';
import { Loader } from './components/Loader/Loader';
import { UserRole } from './helpers/enums';
import { User } from './helpers/interfaces';
import { UserContext } from './context/user-context';
import {Logo} from "./components/Logo/Logo";

if (process.env.NODE_ENV !== 'production') {
  // @ts-ignore
  window.switchRole = () => {
    window.dispatchEvent(new Event('switchRole'));
  }
}

const getAuthenticatedLinks = () => {
  return [[{
    text: 'Clients',
    to: Pages.Clients
  }]];
};

function App({ history }: any) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();
  useEffect(() => {
    onLoad();
  }, [isAuthenticated]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const switchRole = () => {
        if (user) {
          setUser({
            ...user,
            role: user.role === UserRole.admin ? UserRole.developer : UserRole.admin
          });
        }
      };

      window.addEventListener('switchRole', switchRole);

      return () => {
        window.removeEventListener('switchRole', switchRole);
      }
    }
  }, [user]);

  const handleLogin = useCallback((isLoggedIn: boolean) => {
    userHasAuthenticated(isLoggedIn);
  }, []);

  const appProps = useMemo(() => ({
    user,
    isAuthenticated,
    userHasAuthenticated
  }), [user, isAuthenticated, userHasAuthenticated]);

  const userName = useMemo(() => {
    return [user?.attributes['given_name'], user?.attributes['family_name']]
      .filter(val => !!val)
      .join(' ');
  }, [user]);

  async function onLoad() {
    try {
      await Auth.currentSession();
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      const isAdmin = currentAuthenticatedUser.signInUserSession.accessToken.payload["cognito:groups"]?.includes('admins');
      setUser({
        ...(await Auth.currentUserInfo()),
        role: isAdmin ? UserRole.admin : UserRole.developer
      });
      userHasAuthenticated(true);
    }
    catch (e) {
      console.error(e);
    }

    setTimeout(() => {
      setIsAuthenticating(false);
    }, 300);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push(Pages.Login);
    setUser(undefined);
  }

  if (isAuthenticating) {
    return (
      <Loader/>
    )
  }

  return (
    <UserContext.Provider value={user}>
      <Application
        profileLink=""
        username={userName}
        userMail={`${user?.attributes['email']}`}
        isAuthenticated={isAuthenticated}
        onLogoutClick={handleLogout}
        sidebar={!!user ? (
          <Navigation
            user={user}
            titleUserKey={'attributes.custom:company'}
            onLogout={handleLogout}
            links={isAuthenticated ? getAuthenticatedLinks() : []}
            renderLogo={() => (
              <Logo/>
            )}
          />
        ) : <></>}
      >
        <Switch>
          <AppliedRoute path={Pages.Login} component={Login} appProps={{...appProps, onLogin: handleLogin}} />
          <AppliedRoute path={Pages.Clients} exact component={Clients} appProps={appProps} />
          <Redirect to={{
            pathname: Pages.Clients
          }} />
        </Switch>
      </Application>
    </UserContext.Provider>
  );
}

export default withRouter(App);
