import React, { useEffect, useState } from "react";
import "./index.scss";
import { Auth } from "aws-amplify";
import { Storage } from '../../helpers/storage';
import { LoginPage } from '@jumpventures/stack/components/login-page';
import { Constants } from '../../helpers/constants';
import {Logo} from "../../components/Logo/Logo";

type LoginProps = {
  onLogin: (val: boolean) => any;
  history: any;
}

const Login: React.FC<LoginProps> = ({ onLogin }: LoginProps) => {
  const [prevEmail, setPrevEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [prevEmailHasBeenFetched, setPrevEmailHasBeenFetched] = useState(false);

  useEffect(() => {
    const root = document.getElementById('root');

    if (root) {
      root.classList.add('flex-content');
    }

    return () => {
      if (root) {
        root.classList.remove('flex-content');
      }
    }
  }, []);

  useEffect(() => {
    const email = Storage.get(Constants.Storage.UserAccountName);
    if (email) {
      setPrevEmail(email);
    }
    setPrevEmailHasBeenFetched(true);
  }, []);

  async function handleSubmit({email, password, rememberMe}) {
    setErrorMessage(undefined);
    try {
      await Auth.signIn(email, password);
      if (rememberMe) {
        Storage.set(Constants.Storage.UserAccountName, email);
      }
      onLogin(true);
    } catch (e) {
      setErrorMessage(e.message);
    }
  }

  if (!prevEmailHasBeenFetched) {
    return null;
  }

  return (
    <LoginPage
      renderLogo={() => <Logo/>}
      userNameIsEmail={false}
      rememberedEmail={prevEmail}
      errorMessage={errorMessage}
      onSubmit={handleSubmit}
    />
  );
};

export default Login;
